import React, { useEffect } from "react";
import { fabric } from "fabric";
import { APIURL } from "../../config";
import axios from "axios";

export function ProductMargin(editor) {
  const { Canvaseditor, Productmargin } = editor;
  useEffect(() => {
    // Set product margin.
    if (Productmargin) {
      const get_categories = `${APIURL}/api/v1/categories`;
      axios.get(get_categories).then(function (response) {
        if (response.data.data.length > 0 && Canvaseditor) {
          const safe_margin = response.data.data[0].safe_margin;
          const cut_margin = response.data.data[0].cut_margin;
          if (safe_margin && cut_margin) {
            // Cut line.
            const cut_line1 = new fabric.Line(
              [
                cut_margin,
                cut_margin,
                cut_margin,
                Canvaseditor.height - cut_margin,
              ],
              {
                strokeDashArray: [cut_margin, cut_margin],
                stroke: "#d91e18",
              }
            );
            const cut_line2 = new fabric.Line(
              [
                Canvaseditor.width - cut_margin,
                cut_margin,
                Canvaseditor.width - cut_margin,
                Canvaseditor.height - cut_margin,
              ],
              {
                strokeDashArray: [cut_margin, cut_margin],
                stroke: "#d91e18",
              }
            );
            const cut_line3 = new fabric.Line(
              [
                cut_margin,
                cut_margin,
                Canvaseditor.width - cut_margin,
                cut_margin,
              ],
              {
                strokeDashArray: [cut_margin, cut_margin],
                stroke: "#d91e18",
              }
            );
            const cut_line4 = new fabric.Line(
              [
                cut_margin,
                Canvaseditor.height - cut_margin,
                Canvaseditor.width - cut_margin,
                Canvaseditor.height - cut_margin,
              ],
              {
                strokeDashArray: [cut_margin, cut_margin],
                stroke: "#d91e18",
              }
            );

            // End cut line.

            // Safe line.
            const safe_line1 = new fabric.Line(
              [
                safe_margin,
                safe_margin,
                safe_margin,
                Canvaseditor.height - safe_margin,
              ],
              {
                strokeDashArray: [safe_margin, safe_margin],
                stroke: "#90EE90"
              }
            );
            const safe_line2 = new fabric.Line(
              [
                Canvaseditor.width - safe_margin,
                safe_margin,
                Canvaseditor.width - safe_margin,
                Canvaseditor.height - safe_margin,
              ],
              {
                strokeDashArray: [safe_margin, safe_margin],
                stroke: "#90EE90"
              }
            );
            const safe_line3 = new fabric.Line(
              [
                safe_margin,
                safe_margin,
                Canvaseditor.width - safe_margin,
                safe_margin,
              ],
              {
                strokeDashArray: [safe_margin, safe_margin],
                stroke: "#90EE90"
              }
            );
            const safe_line4 = new fabric.Line(
              [
                safe_margin,
                Canvaseditor.height - safe_margin,
                Canvaseditor.width - safe_margin,
                Canvaseditor.height - safe_margin,
              ],
              {
                strokeDashArray: [safe_margin, safe_margin],
                stroke: "#90EE90"
              }
            );
            // End safe line.

            // Cut line group.
            const cut_line_group = new fabric.Group([
              cut_line1,
              cut_line2,
              cut_line3,
              cut_line4,
            ]);
            cut_line_group.selectable = false;
            cut_line_group.evented = false;
            // cut_line_group.hoverCursor = 'default';
            // Safe line group.
            const safe_line_group = new fabric.Group([
              safe_line1,
              safe_line2,
              safe_line3,
              safe_line4,
            ]);
            safe_line_group.selectable = false;
            safe_line_group.evented = false;
            // safe_line_group.hoverCursor = 'default';
            Canvaseditor.add(cut_line_group, safe_line_group);
          }
        }
      });
    }
  }, [Productmargin]);
}
