import React, { useState } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

export function TextColor(editor) {
  const { Canvaseditor } = editor;
  const [colorPicker, setcolorPicker] = useState({
    displayColorPicker: false,
    color: {
      r: '0',
      g: '0',
      b: '0',
      a: '1'
    }
  });

  // Color Picker.
  const handleFontColorClick = () => {
    setcolorPicker({
      displayColorPicker: !colorPicker.displayColorPicker,
      color: {
        r: '0',
        g: '0',
        b: '0',
        a: '1'
      }
    });
  };

  const handleFontColorClose = () => {
    setcolorPicker({
      displayColorPicker: false,
      color: colorPicker.color
    });
  };

  const handleFontColorChange = (color) => {
    if (Canvaseditor.getActiveObject()) {
      setcolorPicker({
        displayColorPicker: true,
        color: color.rgb
      });
      Canvaseditor.getActiveObject().set(
        'fill',
        `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
      );
      Canvaseditor.renderAll();
    }
  };

  // Set css for color picker.
  const styles = reactCSS({
    default: {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `rgba(${colorPicker.color.r}, ${colorPicker.color.g}, ${colorPicker.color.b}, ${colorPicker.color.a})`
      },
      swatch: {
        display: 'inline-block',
        background: `rgba(${colorPicker.color.r}, ${colorPicker.color.g}, ${colorPicker.color.b}, ${colorPicker.color.a})`,
        width: '37px',
        height: '34px',
        borderRadius: '2px',
        boxShadow: '0 0 2px rgb(16 22 26 / 30%)',
        cursor: 'pointer'
      },
      popover: {
        position: 'fixed',
        zIndex: '2'
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
      }
    }
  });

  return (
    <div className="font-color-container">
      <div>
        <div style={styles.swatch} onClick={handleFontColorClick}>
          <div style={styles.color} />
        </div>
        {colorPicker.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={handleFontColorClose} />
            <SketchPicker color={colorPicker.color} onChange={handleFontColorChange} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
