import React from 'react';
import { fontFamilyPicker } from '../../FontFamily';

export function FontFamily(editor) {
  const { Canvaseditor } = editor;
  // Font Picker.
  const handleFontFamilyChange = (event) => {
    if (Canvaseditor.getActiveObject()) {
      let activeObject = Canvaseditor.getActiveObject();
      activeObject.set('fontFamily', event.target.value);
      Canvaseditor.renderAll();
    }
  };

  return (
    <div className="font-familiy-container">
      <div id="font-picker">
        <select
          style={{ padding: '7px 3px 4px 12px' }}
          id="font-family"
          onChange={handleFontFamilyChange}
        >
          <option value="">Select Font</option>
          {fontFamilyPicker.map((getFontFamily) => (
            <option key={getFontFamily} value={getFontFamily} style={{ fontFamily: getFontFamily }}>
              {getFontFamily}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
