import React, { useState } from "react";
import { fabric } from "fabric";
import { ElementsIcon } from './ElementsIcon';
import { ElementsCategories } from './ElementsCategories';

export function ElementsComponet(editor) {
  const {
    Canvaseditor,
    setShowToolbar,
    setTextToolbar,
    setelementStrokColor,
    setshowUploadToolbar,
    closeTab,
  } = editor;
  const [Elements, setElements] = useState([]);
  // Generate Elements.
  const generateElement = (e) => {
    new fabric.loadSVGFromURL(e.target.src, function (objects, options) {
      setShowToolbar(true);
      setTextToolbar(false);
      const obj = fabric.util.groupSVGElements(objects, options);
      obj.scaleToWidth(100);
      obj.scaleToHeight(100);
      obj.on("selected", function () {
        setTextToolbar(false);
        setelementStrokColor(true);
        setshowUploadToolbar(false);
      });
      Canvaseditor.add(obj);
      Canvaseditor.centerObject(obj);
      obj.center().setCoords();
      Canvaseditor.setActiveObject(obj).renderAll();
    });
    // Close tab event.
    const resizeWidth = window.innerWidth;
    if (resizeWidth <= 770) {
      closeTab();
    }
  };

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <ElementsCategories setElements={setElements} Elements={Elements} />
      <ElementsIcon setElements={setElements} Elements={Elements} generateElement={generateElement} />
    </div>
  );
}
