import React, { useEffect } from "react";
import { TextColor } from "./Text/TextColor";
import { FontFamily } from "./Text/FontFamily";
import { FontSize } from "./Text/FontSize";
import { FontStyle } from "./Text/FontStyle";
import { Position } from "./Text/Position";
import { Transparency } from "./Text/Transparency";
import { LockElement } from "./LockElement";
import { Duplicate } from "./Duplicate";
import { DeleteElement } from "./DeleteElement";
import { SaveContinue } from "./SaveContinue";
import { FlipPosition } from "./FlipPosition";
import { ElementStrokColor } from "./ElementStrokColor";
import { useParams } from "react-router-dom";

export function ToolBar(editor) {
  const { createID, updateID } = useParams();
  const {
    Canvaseditor,
    disabledObj,
    setdisabledObj,
    showTextToolbar,
    elementStrokColor,
    showUploadToolbar,
  } = editor;

  useEffect(() => {
    // On object modified.
    Canvaseditor.on("object:modified", function (event) {
      if (event.target && typeof event.target.text !== "undefined") {
        event.target.fontSize *= event.target.scaleX;
        event.target.fontSize = event.target.fontSize.toFixed(0);
        event.target.scaleX = 1;
        event.target.scaleY = 1;
        event.target._clearCache();
        document.getElementById("text-font-size").value = event.target.fontSize;
        let activeTextObject = Canvaseditor.getActiveObject();
        if (activeTextObject) {
          activeTextObject.set("fontSize", event.target.fontSize);
          Canvaseditor.renderAll();
        }
      }
    });

    // On object select.
    Canvaseditor.on("selection:created", function () {
      setdisabledObj(false);
    });

    // On object un select.
    Canvaseditor.on("selection:cleared", function () {
      setdisabledObj(true);
    });

    // Unselect cut & safe margin.
    var activeObject = Canvaseditor.getActiveObject();
    if (activeObject && activeObject.type === "group") {
      activeObject.selectable = false;
      activeObject.evented = false;
      activeObject.hoverCursor = "default";
      Canvaseditor.discardActiveObject().renderAll();
    }
  });

  return (
    <nav className="bp4-navbar">
      <div className="bp4-navbar-group bp4-align-left">
        {!disabledObj && (
          <>
            {showUploadToolbar && <FlipPosition Canvaseditor={Canvaseditor} />}
            {elementStrokColor && (
              <ElementStrokColor Canvaseditor={Canvaseditor} />
            )}
            {showTextToolbar && (
              <>
                <TextColor Canvaseditor={Canvaseditor} />
                <FontFamily
                  Canvaseditor={Canvaseditor}
                />
                <FontSize Canvaseditor={Canvaseditor} />
                <div className="font-style-container">
                  <FontStyle Canvaseditor={Canvaseditor} />
                </div>
              </>
            )}
          </>
        )}
        {showTextToolbar && (
          <>
            <Position Canvaseditor={Canvaseditor} disabledObj={disabledObj} />
            <Transparency
              Canvaseditor={Canvaseditor}
              disabledObj={disabledObj}
            />
          </>
        )}
        <LockElement
          Canvaseditor={Canvaseditor}
          setdisabledObj={setdisabledObj}
        />
        <Duplicate Canvaseditor={Canvaseditor} disabledObj={disabledObj} />
        <DeleteElement
          Canvaseditor={Canvaseditor}
          setdisabledObj={setdisabledObj}
          disabledObj={disabledObj}
        />
      </div>
      {(createID || updateID) && (
        <SaveContinue
          updateID={updateID}
          createID={createID}
          Canvaseditor={Canvaseditor}
        />
      )}
    </nav>
  );
}
