import React, { useState } from "react";
import { fabric } from "fabric";
import { Icon, Button } from "@blueprintjs/core";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";

export function BackgroundComponet(editor) {
  const { Canvaseditor, setShowToolbar, closeTab } = editor;
  const [bgColorPicker, setbgcolorPicker] = useState({
    displayColorPicker: false,
    color: {
      r: "255",
      g: "255",
      b: "255",
      a: "100",
    },
  });
  // Upload BG Image.
  const uploadBGImage = (e) => {
    Canvaseditor.setBackgroundColor(
      "",
      Canvaseditor.renderAll.bind(Canvaseditor)
    );
    Canvaseditor.setBackgroundImage(
      0,
      Canvaseditor.renderAll.bind(Canvaseditor)
    );
    var file = e.target.files[0];
    var fsize = Math.round(file.size / 1024);
    if (fsize > 2048) {
      alert("File too big, please select a file less than 2mb");
      return;
    }
    var reader = new FileReader();
    reader.onload = function (f) {
      var data = f.target.result;
      fabric.Image.fromURL(data, function (img) {
        setShowToolbar(true);

        img.set({
          originX: "left",
          scaleX: Canvaseditor.getWidth() / img.width, //new update
          scaleY: Canvaseditor.getHeight() / img.height, //new update
          originY: "top",
        });
        Canvaseditor.setBackgroundImage(
          img,
          Canvaseditor.renderAll.bind(Canvaseditor)
        );
      });
    };
    reader.readAsDataURL(file);

    // Close tab event.
    const resizeWidth = window.innerWidth;
    if (resizeWidth <= 770) {
      closeTab();
    }
  };

  // Remove BG Image.
  const removeBGImage = () => {
    Canvaseditor.setBackgroundColor(
      "",
      Canvaseditor.renderAll.bind(Canvaseditor)
    );
    Canvaseditor.setBackgroundImage(
      0,
      Canvaseditor.renderAll.bind(Canvaseditor)
    );
    Canvaseditor.renderAll();
    // Close tab event.
    const resizeWidth = window.innerWidth;
    if (resizeWidth <= 770) {
      closeTab();
    }
  };

  // Solid BG Color.
  const solidBGColor = (rgb, r, g, b) => {
    setShowToolbar(true);
    Canvaseditor.setBackgroundColor(
      rgb,
      Canvaseditor.renderAll.bind(Canvaseditor)
    );
    Canvaseditor.setBackgroundImage(
      0,
      Canvaseditor.renderAll.bind(Canvaseditor)
    );
    Canvaseditor.renderAll();
    // Close tab event.
    const resizeWidth = window.innerWidth;
    if (resizeWidth <= 770) {
      closeTab();
    }
  };

  // Color Picker.
  const handleBgColorClick = () => {
    setbgcolorPicker({
      displayColorPicker: !bgColorPicker.displayColorPicker,
      color: {
        r: "255",
        g: "255",
        b: "255",
        a: "100",
      },
    });
  };

  const handleBgColorClose = () => {
    setbgcolorPicker({ displayColorPicker: false, color: bgColorPicker.color });
  };

  const handleBgColorChange = (color) => {
    setShowToolbar(true);
    setbgcolorPicker({ displayColorPicker: true, color: color.rgb });
    // const getBG = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    const getBG = `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`;
    Canvaseditor.setBackgroundColor(
      getBG,
      Canvaseditor.renderAll.bind(Canvaseditor)
    );
    Canvaseditor.setBackgroundImage(
      0,
      Canvaseditor.renderAll.bind(Canvaseditor)
    );
    Canvaseditor.renderAll();
  };

  // Set css for color picker.
  const bgStyles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        // background: `rgba(${bgColorPicker.color.r}, ${bgColorPicker.color.g}, ${bgColorPicker.color.b}, ${bgColorPicker.color.a})`,
        background: `rgb(${bgColorPicker.color.r}, ${bgColorPicker.color.g}, ${bgColorPicker.color.b})`,
      },
      swatch: {
        display: "inline-block",
        // background: `rgba(${bgColorPicker.color.r}, ${bgColorPicker.color.g}, ${bgColorPicker.color.b}, ${bgColorPicker.color.a})`,
        background: `rgb(${bgColorPicker.color.r}, ${bgColorPicker.color.g}, ${bgColorPicker.color.b})`,
        width: "41px",
        height: "41px",
        borderRadius: "2px",
        boxShadow: "0 0 2px rgb(16 22 26 / 30%)",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "absolute",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ marginBottom: "30px" }}>
        <label
          htmlFor="bg-input-file"
          className="bp4-button"
          style={{ width: "45%", marginRight: "10px" }}
        >
          <Icon size="18" icon="upload" />
          <span className="bp4-button-text">Upload BG</span>
        </label>
        <Button
          id="removeBG"
          text="Remove BG"
          onClick={removeBGImage}
          icon={<Icon icon="graph-remove" />}
          style={{ width: "45%" }}
        />
        <input
          onChange={uploadBGImage}
          type="file"
          id="bg-input-file"
          style={{ display: "none" }}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <h4>Solid Colors </h4>
        <div
          style={{
            display: "flex",
            paddingBottom: "10px",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <div className="solid-color">
            <div
              onClick={() => solidBGColor("rgb(255, 255, 255)", 255, 255, 255)}
              style={{
                width: "100%",
                height: "100%",
                background: "rgb(255, 255, 255)",
              }}
            ></div>
          </div>
          <div className="solid-color">
            <div
              onClick={() => solidBGColor("rgb(82, 113, 255)", 82, 113, 255)}
              style={{
                width: "100%",
                height: "100%",
                background: "rgb(82, 113, 255)",
              }}
            ></div>
          </div>
          <div className="solid-color">
            <div
              onClick={() => solidBGColor("rgb(255, 145, 77)", 255, 145, 77)}
              style={{
                width: "100%",
                height: "100%",
                background: "rgb(255, 145, 77)",
              }}
            ></div>
          </div>
          <div className="solid-color">
            <div
              onClick={() => solidBGColor("rgb(126, 217, 87)", 126, 217, 87)}
              style={{
                width: "100%",
                height: "100%",
                background: "rgb(126, 217, 87)",
              }}
            ></div>
          </div>
          <div className="solid-color">
            <div
              onClick={() => solidBGColor("rgb(255, 222, 89)", 255, 222, 89)}
              style={{
                width: "100%",
                height: "100%",
                background: "rgb(255, 222, 89)",
              }}
            ></div>
          </div>
          <div className="solid-color">
            <div
              onClick={() => solidBGColor("rgb(203, 108, 230)", 203, 108, 230)}
              style={{
                width: "100%",
                height: "100%",
                background: "rgb(203, 108, 230)",
              }}
            ></div>
          </div>
          <div className="solid-color">
            <div
              onClick={() => solidBGColor("rgb(208, 2, 27)", 208, 2, 27)}
              style={{
                width: "100%",
                height: "100%",
                background: "rgb(208, 2, 27)",
              }}
            ></div>
          </div>

          <div className="solid-color">
            <div
              onClick={() => solidBGColor("rgb(245, 166, 35)", 245, 166, 35)}
              style={{
                width: "100%",
                height: "100%",
                background: "rgb(245, 166, 35)",
              }}
            ></div>
          </div>
          <div className="solid-color">
            <div
              onClick={() => solidBGColor("rgb(248, 231, 28)", 248, 231, 28)}
              style={{
                width: "100%",
                height: "100%",
                background: "rgb(248, 231, 28)",
              }}
            ></div>
          </div>
          <div className="solid-color">
            <div
              onClick={() => solidBGColor("rgb(139, 87, 42)", 139, 87, 42)}
              style={{
                width: "100%",
                height: "100%",
                background: "rgb(139, 87, 42)",
              }}
            ></div>
          </div>
          <div className="solid-color">
            <div
              onClick={() => solidBGColor("rgb(184, 233, 134)", 184, 233, 134)}
              style={{
                width: "100%",
                height: "100%",
                background: "rgb(184, 233, 134)",
              }}
            ></div>
          </div>
          <div className="solid-color">
            <div
              onClick={() => solidBGColor("rgb(65, 117, 5)", 65, 117, 5)}
              style={{
                width: "100%",
                height: "100%",
                background: "rgb(65, 117, 5)",
              }}
            ></div>
          </div>
          <div className="solid-color">
            <div
              onClick={() => solidBGColor("rgb(74, 144, 226)", 74, 144, 226)}
              style={{
                width: "100%",
                height: "100%",
                background: "rgb(74, 144, 226)",
              }}
            ></div>
          </div>
          <div className="solid-color">
            <div
              onClick={() => solidBGColor("rgb(80, 227, 202)", 80, 227, 202)}
              style={{
                width: "100%",
                height: "100%",
                background: "rgb(80, 227, 202)",
              }}
            ></div>
          </div>
          <div className="solid-color">
            <div
              onClick={() => solidBGColor("rgb(0, 0, 0)", 0, 0, 0)}
              style={{
                width: "100%",
                height: "100%",
                background: "rgb(0, 0, 0)",
              }}
            ></div>
          </div>
        </div>
        <div className="font-color-container">
          <div>
            <div style={bgStyles.swatch} onClick={handleBgColorClick}>
              <Icon
                icon="color-fill"
                style={{ marginLeft: "10px", marginTop: "10px" }}
              />
              <div style={bgStyles.color} />
            </div>
            {bgColorPicker.displayColorPicker ? (
              <div style={bgStyles.popover}>
                <div style={bgStyles.cover} onClick={handleBgColorClose} />
                <SketchPicker
                  color={bgColorPicker.color}
                  onChange={handleBgColorChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
