import React, { useState, useEffect } from "react";
import { APIURL } from "../../config";
import axios from "axios";

export function Logo() {
    const [siteLogo, setSiteLogo] = useState(
      process.env.PUBLIC_URL + "/site-logo.png"
    );
    useEffect(() => {
    // Get site logo.
    const site_logo = `${APIURL}/api/v1/site-logo`;
    axios
      .get(site_logo)
      .then(function (response) {
        if (response.data.data.length > 0) {
          setSiteLogo(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }, []);

    return <img src={siteLogo} />;
}
