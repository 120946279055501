import React from 'react';
import { Classes, Popover2 } from '@blueprintjs/popover2';
import { Icon, Button, Menu, MenuItem } from '@blueprintjs/core';

export function Position(editor) {
  const { Canvaseditor, disabledObj } = editor;
  // Hanlde position element.
  const handleLeftAlignment = () => {
    if (Canvaseditor.getActiveObject()) {
      Canvaseditor.getActiveObject().set('left', 0);
      Canvaseditor.renderAll();
    }
  };

  const handleCenterAlignment = () => {
    if (Canvaseditor.getActiveObject()) {
      Canvaseditor.getActiveObject().set('left', Canvaseditor.width / 4);
      Canvaseditor.renderAll();
    }
  };

  const handleRightAlignment = () => {
    if (Canvaseditor.getActiveObject()) {
      Canvaseditor.getActiveObject().set('left', Canvaseditor.width / 2);
      Canvaseditor.renderAll();
    }
  };

  const handleTopAlignment = () => {
    if (Canvaseditor.getActiveObject()) {
      Canvaseditor.getActiveObject().set('top', 0);
      Canvaseditor.renderAll();
    }
  };

  const handleMiddleAlignment = () => {
    if (Canvaseditor.getActiveObject()) {
      Canvaseditor.getActiveObject().set('top', Canvaseditor.height / 2.5);
      Canvaseditor.renderAll();
    }
  };

  const handleBottomAlignment = () => {
    if (Canvaseditor.getActiveObject()) {
      Canvaseditor.getActiveObject().set('top', Canvaseditor.getHeight() - 45);
      Canvaseditor.renderAll();
    }
  };

  return (
    <div className="position">
      <Popover2
        interactionKind="click"
        popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
        placement="bottom"
        content={
          <Menu>
            <MenuItem onClick={handleLeftAlignment} icon="alignment-left" text="Align left" />
            <MenuItem
              onClick={handleCenterAlignment}
              icon="alignment-vertical-center"
              text="Align center"
            />
            <MenuItem onClick={handleRightAlignment} icon="alignment-right" text="Align right" />
            <MenuItem onClick={handleTopAlignment} icon="alignment-top" text="Align top" />
            <MenuItem
              onClick={handleMiddleAlignment}
              icon="alignment-horizontal-center"
              text="Align middle"
            />
            <MenuItem onClick={handleBottomAlignment} icon="alignment-bottom" text="Align bottom" />
          </Menu>
        }
        renderTarget={({ ref, ...targetProps }) => (
          <Button
            disabled={disabledObj}
            {...targetProps}
            elementRef={ref}
            icon={<Icon icon="layers" />}
            text="Position"
          />
        )}
      />
    </div>
  );
}
