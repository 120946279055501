import React, { useState } from "react";
import { Icon, Button } from "@blueprintjs/core";
import axios from "axios";

export function AddToCart(editor) {
  const [saveloader, setsaveloader] = useState(false);
  const { Canvaseditor, sessionId, apiUrl, cartKey, loadCategories } = editor;

  // Save & Download.
  const svgToSVG = () => {
    setsaveloader(true);
    if (sessionId && apiUrl && cartKey && loadCategories) {
      if (sessionId && typeof sessionId !== "undefined") {
        Canvaseditor.discardActiveObject();
        Canvaseditor.renderAll();
        const pngData = Canvaseditor.toDataURL("image/png");
        const svgData = Canvaseditor.toSVG();
        const canvas_png_img = pngData;
        const api_url = atob(atob(apiUrl));
        const bgColor = Canvaseditor.backgroundColor;
        const encodedData = JSON.stringify({
          session_id: sessionId,
          cart_key: cartKey,
        });
        axios
          .post(api_url, {
            token: btoa(encodedData),
            base64_img: canvas_png_img,
            base64_svg: svgData,
            canvas_size: localStorage.getItem("canvas_size"),
            bg_color: bgColor,
          })
          .then(function (response) {
            setsaveloader(false);
            if (response.data.data.message) {
              window.location.href = response.data.data.cart_url;
            } else {
              alert(response.data.data.error);
            }
          })
          .catch(function (error) {
            setsaveloader(false);
            console.log(error);
            alert("Oops!something went wrong during save.");
          });
      } else {
        alert("Unauthorized information.");
      }
    }
  };

  return (
    <div className="bp4-navbar-group bp4-align-right">
      {saveloader && (
        <Button icon={<Icon icon="download" />} text="Loading..." />
      )}
      {!saveloader && (
        <Button
          onClick={svgToSVG}
          icon={<Icon icon="download" />}
          text="Save & Continue"
        />
      )}
    </div>
  );
}
