export const fontFamilyPicker = [
  'Roboto',
  'Rock Salt',
  'Quattrocento',
  'Bilbo Swash Caps',
  'Alata',
  'Yeseva One',
  'Oswald',
  'Open Sans',
  'Akronim',
  'Stylish',
  'Baloo Bhai',
  'Modak',
  'Anton',
  'Lobster',
  'Pacifico',
  'Orbitron',
  'Just Another Hand',
  'Acme',
  'Gloria Hallelujah',
  'Permanent Marker',
  'Satisfy',
  'Lalezar',
  'Great Vibes',
  'Lobster Two',
  'Fredoka One',
  'Luckiest Guy',
  'Merienda',
  'Playball',
  'Patrick Hand',
  'Bangers',
  'Prata',
  'Ultra',
  'Saira',
  'Architects Daughter',
  'Audiowide',
  'Bad Script',
  'Yellowtail',
  'Allerta Stencil',
  'Damion',
  'Covered By Your Grace',
  'Shadows Into Light Two',
  'Arbutus Slab',
  'Nova Script',
  'Parisienne',
  'Alegreya Sans SC',
  'Allura',
  'Homemade Apple',
  'Archivo',
  'Days One',
  'Chewy',
  'Allerta',
  'Nothing You Could Do',
  'Schoolbell',
  'Jura',
  'Black Ops One',
  'Mr Dafoe',
  'Staatliches',
  'Prosto One',
  'Alex Brush',
  'Lemonada',
  'Michroma',
  'Niconne',
  'Itim',
  'Reenie Beanie',
  'Leckerli One',
  'Grand Hotel',
  'Shojumaru',
  'VT323',
  'Yesteryear',
  'Aclonica',
  'Jockey One',
  'Shrikhand',
  'Raleway Dots',
  'Raleway',
  'Knewave',
  'Delius',
  'Bungee Inline',
  'Calligraffitti',
  'Gabriela',
  'Metrophobic',
  'Do Hyeon',
  'Suranna',
  'Freckle Face',
  'Limelight',
  'Merienda One',
  'Mr De Haviland',
  'ZCOOL KuaiLe',
  'Mukta Vaani',
  'ZCOOL XiaoWei',
  'Tulpen One',
  'Cedarville Cursive',
  'Qwigley',
  'Federo',
  'IM Fell English',
  'Andika',
  'Medula One',
  'Spicy Rice',
  'Bungee Shade',
  'Finger Paint',
  'Holtwood One SC',
  'Frijole',
  'Creepster',
  'Nosifer',
  'Sarina',
  'Notable',
  'Trade Winds',
  'League Script',
  'Ranchers',
  'Fascinate Inline',
  'Plaster',
  'Redressed',
  'Miniver',
  'Henny Penny',
  'UnifrakturCook',
  'Mina',
  'Smokum',
  'Astloch',
  'Molle',
  'Modern Antiqua',
  'Kenia',
  'Caesar Dressing',
  'IM Fell Great Primer SC',
  'Iceberg',
  'IM Fell French Canon SC',
  'Oldenburg',
  'Jacques Francois Shadow',
  'Diplomata SC',
  'Snowburst One',
  'Snippet',
  'Rum Raisin',
  'Sahitya',
  'East Sea Dokdo',
  'Original Surfer',
  'Ribeye Marrow',
  'Black And White Picture',
  'Ewert',
  'Arbutus',
  'Hi Melody',
  'Miss Fajardose',
  'Devonshire',
  'Kavivanar',
  'Meie Script',
  'Piedra',
  'Macondo',
  'Ruge Boogie',
  'Bungee Outline',
  'Bungee Hairline',
  'Risque',
  'Butcherman',
  'Unlock',
  'Geostar',
  'Londrina Sketch',
  'Stalinist One',
  'Peddana',
  'Kumar One Outline',
  'Warnes',
  'BioRhyme Expanded',
  'Dhurjati'
];
