import React from "react";
import {
  defaultFontfamily,
  customFontfamily,
  customFontfamilySection2,
} from "../FontFamily/fontfamily";
import { fabric } from "fabric";

export function TextComponet(editor) {
  const {
    Canvaseditor,
    setShowToolbar,
    setTextToolbar,
    setelementStrokColor,
    setshowUploadToolbar,
    closeTab,
  } = editor;

  // Add Text Onclick Event.
  const addText = (description, dynamicFont, size) => {
    if (Canvaseditor) {
      setShowToolbar(true);
      setTextToolbar(true);
      const Addtext = new fabric.Textbox(description, {
        fill: "black",
        width: 300,
        textAlign: "center",
        fontFamily: dynamicFont,
        fontSize: size,
        editable: true,
        selectable: true,
      });

      // Object selected.
      Addtext.on("selected", function () {
        setTextToolbar(true);
        setelementStrokColor(false);
        setshowUploadToolbar(false);
      });

      Canvaseditor.add(Addtext).setActiveObject(Addtext);
      Canvaseditor.centerObject(Addtext);
      Canvaseditor.renderAll();

      setTimeout(function () {
        document.getElementById("txtcenter").style.opacity = "1";
        document.getElementById("txtleft").style.opacity = "0.5";
        document.getElementById("txtright").style.opacity = "0.5";
        document.getElementById("font-family").value = dynamicFont;
        document.getElementById("text-font-size").value = size;
      }, 0);

      // Close tab event.
      const resizeWidth = window.innerWidth;
      if (resizeWidth <= 770) {
        closeTab();
      }
    }
  };

  return (
    <>
      <div className="col">
        {defaultFontfamily.map((getFontFamily) => (
          <button
            key={getFontFamily.id}
            onClick={() =>
              addText(
                getFontFamily.text,
                getFontFamily.font,
                getFontFamily.size
              )
            }
            className="bp4-button bp4-minimal"
            type="button"
            style={{
              fontFamily: getFontFamily.font,
              marginBottom: "5px",
              fontSize: getFontFamily.buttonSize,
              width: "100%",
            }}
          >
            <span className="bp4-button-text">Create header</span>
          </button>
        ))}
      </div>
      <div className="go4160152499">
        <div className="go2497765715" style={{ width: "50%" }}>
          {customFontfamily.map((loadFontFamily) => (
            <div
              key={loadFontFamily.id}
              className="go3168597731 polotno-close-panel"
            >
              <div className="go2452636514">
                <img
                  onClick={() =>
                    addText(
                      loadFontFamily.text,
                      loadFontFamily.font,
                      loadFontFamily.size
                    )
                  }
                  alt="text-font"
                  className="go3457971912 polotno-text-preview-plain"
                  src={process.env.PUBLIC_URL + loadFontFamily.img}
                  style={{ height: "auto" }}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="go2497765715" style={{ width: "50%" }}>
          {customFontfamilySection2.map((load2FontFamily) => (
            <div
              key={load2FontFamily.id}
              className="go3168597731 polotno-close-panel"
            >
              <div className="go2452636514">
                <img
                  onClick={() =>
                    addText(
                      load2FontFamily.text,
                      load2FontFamily.font,
                      load2FontFamily.size
                    )
                  }
                  alt="text-font"
                  className="go3457971912 polotno-text-preview-plain"
                  src={process.env.PUBLIC_URL + load2FontFamily.img}
                  style={{ height: "auto" }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
