import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { TextComponet } from "../../components/Text";
import { ToolBar } from "../../components/Toolbar";
import { ElementsComponet } from "../../components/Elements";
import { fontFamilyPicker } from "../../components/FontFamily";
import { UploadComponet } from "../../components/Upload";
import { Templates } from "../../components/Templates";
import { BackgroundComponet } from "../../components/Background";
import { ZoomCanvas } from "../../components/Zoom";
import { AddToCart } from "../../components/Toolbar/AddToCart";
import { Logo } from "../../components/Logo";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Tabs, Tab, Icon, Button } from "@blueprintjs/core";
import WebFont from "webfontloader";
import "./home.css";

export function Home() {
  const { editor, onReady } = useFabricJSEditor();
  const [showTextToolbar, setTextToolbar] = useState(false);
  const [showToolbar, setShowToolbar] = useState(false);
  const [disabledObj, setdisabledObj] = useState(false);
  const [canvasObj, setcanvasObj] = useState(false);
  const [loader, setLoader] = useState(false);
  const [elementStrokColor, setelementStrokColor] = useState(false);
  const [showUploadToolbar, setshowUploadToolbar] = useState(false);
  const [activeTab, setActiveTab] = useState("Templates");
  const { sessionId, apiUrl, cartKey, loadCategories } = useParams();

  const __onReady = (editor) => {
    var ratio1 = Math.min(600 / 2304, 600 / 1920);
    editor.setDimensions({
      width: 2304 * ratio1,
      height: 1920 * ratio1,
    });
    setcanvasObj(editor);
    onReady(editor);
  };

  useEffect(() => {
    // Load webfont.
    WebFont.load({
      google: {
        families: fontFamilyPicker,
      },
    });
  }, []);

  // Tab change event.
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    // Additional logic for tab click event
    document
      .getElementById(`bp4-tab-panel_sidepanel_${tabId}`)
      .setAttribute("aria-hidden", "false");
  };

  // Close tab event.
  const closeTab = () => {
    const elements = document.querySelectorAll(".bp4-tab-panel");
    elements.forEach((element) => {
      element.setAttribute("aria-hidden", "true");
    });
  };

  return (
    <div className="main-container">
      <div className="top-header">
        <div className="site-logo">
          <Logo />
        </div>
        {sessionId && apiUrl && cartKey && loadCategories && (
          <div className="addto-cart">
            <AddToCart
              sessionId={sessionId}
              apiUrl={apiUrl}
              cartKey={cartKey}
              loadCategories={loadCategories}
              Canvaseditor={canvasObj}
            />
          </div>
        )}
      </div>
      <div className="tabpanel">
        <div className="side-panel">
          <Tabs
            id="sidepanel"
            animate={false}
            vertical={true}
            large={false}
            selectedTabId={activeTab}
            onChange={handleTabChange}
          >
            <Tab
              id="Templates"
              title={
                <>
                  <div className="left-tab">
                    <Icon size="18" icon="control" />
                  </div>
                  <div className="left-tab-text">Templates</div>
                </>
              }
              panel={
                <div className="tab-content">
                  <Templates
                    Canvaseditor={canvasObj}
                    setshowUploadToolbar={setshowUploadToolbar}
                    setelementStrokColor={setelementStrokColor}
                    setTextToolbar={setTextToolbar}
                    setShowToolbar={setShowToolbar}
                    setLoader={setLoader}
                    loader={loader}
                    closeTab={closeTab}
                  />
                  <Button
                    onClick={closeTab}
                    className="close-tab"
                    icon={<Icon icon="caret-left" />}
                  />
                </div>
              }
            />
            <Tab
              id="Text"
              title={
                <>
                  <div className="left-tab">
                    <Icon size="18" icon="new-text-box" />
                  </div>
                  <div className="left-tab-text">Text</div>
                </>
              }
              panel={
                <div className="tab-content">
                  <TextComponet
                    setshowUploadToolbar={setshowUploadToolbar}
                    setelementStrokColor={setelementStrokColor}
                    setTextToolbar={setTextToolbar}
                    Canvaseditor={canvasObj}
                    setShowToolbar={setShowToolbar}
                    closeTab={closeTab}
                  />
                  <Button
                    onClick={closeTab}
                    className="close-tab"
                    icon={<Icon icon="caret-left" />}
                  />
                </div>
              }
            />
            <Tab
              id="Elements"
              title={
                <>
                  <div className="left-tab">
                    <Icon size="18" icon="comparison" />
                  </div>
                  <div className="left-tab-element">Elements</div>
                </>
              }
              panel={
                <div className="tab-content">
                  <ElementsComponet
                    setshowUploadToolbar={setshowUploadToolbar}
                    setelementStrokColor={setelementStrokColor}
                    setTextToolbar={setTextToolbar}
                    Canvaseditor={canvasObj}
                    setShowToolbar={setShowToolbar}
                    closeTab={closeTab}
                  />
                  <Button
                    onClick={closeTab}
                    className="close-tab"
                    icon={<Icon icon="caret-left" />}
                  />
                </div>
              }
            />
            <Tab
              id="Uploads"
              title={
                <>
                  <div className="left-tab">
                    <Icon size="18" icon="cloud-upload" />
                  </div>
                  <div className="left-tab-element">Uploads</div>
                </>
              }
              panel={
                <div className="tab-content">
                  <UploadComponet
                    setshowUploadToolbar={setshowUploadToolbar}
                    setelementStrokColor={setelementStrokColor}
                    setTextToolbar={setTextToolbar}
                    Canvaseditor={canvasObj}
                    setShowToolbar={setShowToolbar}
                    closeTab={closeTab}
                  />
                  <Button
                    onClick={closeTab}
                    className="close-tab"
                    icon={<Icon icon="caret-left" />}
                  />
                </div>
              }
            />
            <Tab
              id="Background"
              title={
                <>
                  <div className="left-tab">
                    <Icon size="18" icon="layout-grid" />
                  </div>
                  <div className="left-tab-element">Background</div>
                </>
              }
              panel={
                <div className="tab-content">
                  <BackgroundComponet
                    Canvaseditor={canvasObj}
                    setShowToolbar={setShowToolbar}
                    closeTab={closeTab}
                  />
                  <Button
                    onClick={closeTab}
                    className="close-tab"
                    icon={<Icon icon="caret-left" />}
                  />
                </div>
              }
            />
          </Tabs>
        </div>
      </div>
      <div className="canvas-panel">
        {showToolbar ? (
          <ToolBar
            showUploadToolbar={showUploadToolbar}
            elementStrokColor={elementStrokColor}
            Canvaseditor={canvasObj}
            setdisabledObj={setdisabledObj}
            disabledObj={disabledObj}
            showTextToolbar={showTextToolbar}
          />
        ) : null}
        <div className="main-area">
          <div className="canvas-area">
            <FabricJSCanvas className="sample-canvas" onReady={__onReady} />
            {loader && <div id="cover-spin"></div>}
          </div>
          <div className="zomm-canvas">
            <ZoomCanvas Canvaseditor={canvasObj} />
          </div>
        </div>
      </div>
    </div>
  );
}
