import React, { useState } from 'react';
import { Icon, Button } from '@blueprintjs/core';
import axios from 'axios';
import { APIURL } from '../../config';

export function SaveContinue(editor) {
  const [saveloader, setsaveloader] = useState(false);
  const { Canvaseditor, createID, updateID } = editor;

  // Save & Download.
  const saveTemplate = () => {
    // var trsvg = Canvaseditor.toSVG();
    setsaveloader(true);
    Canvaseditor.discardActiveObject();
    Canvaseditor.renderAll();
    const dataURL = Canvaseditor.toDataURL('image/png');
    const canvas_img = dataURL;
    const json_data = escape(JSON.stringify(Canvaseditor.toDatalessJSON(['customType'])));
    const save_api = `${APIURL}/api/v1/save`;
    if (createID) {
      axios
        .post(save_api, {
          images: canvas_img,
          jasondata: json_data,
          template_id: createID
        })
        .then(function (response) {
          setsaveloader(false);
          if (response.data.msg) {
            window.location.href = `${APIURL}/templates`;
          } else {
            alert('Oops!something went wrong during save, please try again.');
          }
        })
        .catch(function (error) {
          setsaveloader(false);
          console.log(error);
          alert('Oops!something went wrong during save.');
        });
    } else {
      axios
        .put(save_api, {
          images: canvas_img,
          jasondata: json_data,
          template_id: updateID
        })
        .then(function (response) {
          setsaveloader(false);
          if (response.data.msg) {
            window.location.href = `${APIURL}/templates`;
          } else {
            alert('Oops!something went wrong during save, please try again.');
          }
        })
        .catch(function (error) {
          setsaveloader(false);
          console.log(error);
          alert('Oops!something went wrong during save.');
        });
    }
  };
  return (
    <div className="bp4-navbar-group bp4-align-right">
      {saveloader && <Button icon={<Icon icon="download" />} text="Loading..." />}
      {!saveloader && (
        <Button onClick={saveTemplate} icon={<Icon icon="download" />} text="Save & Continue" />
      )}
    </div>
  );
}
