import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { PageNotFound } from './pages/404';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/:createID/create" element={<Home />}></Route>
        <Route exact path="/:updateID/update" element={<Home />}></Route>
        <Route
          exact
          path="/:sessionId/:apiUrl/:cartKey/:loadCategories/user-edit"
          element={<Home />}
        ></Route>
        <Route path="/*" element={<PageNotFound />}></Route>
      </Routes>
    </Router>
  );
}
