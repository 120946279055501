import React, { useEffect } from "react";
import { APIURL } from "../../config";
import axios from "axios";

export function ElementsIcon(editor) {
  const { generateElement, setElements, Elements } = editor;
  useEffect(() => {
    // Set default elements.
    const default_elements = `${APIURL}/api/v1/default-elements`;
    axios
      .get(default_elements)
      .then(function (response) {
        if (response.data.data.length > 0) {
          setElements(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <>
      {Elements.length <= 0 && <span className="no-elements">No elements found.</span>}
      {Elements.length > 0 &&
        Elements.map(function (defaultElt, index) {
          return (
            <div className="elements" key={index}>
              <img
                onClick={(e) => generateElement(e)}
                alt="elements"
                src={defaultElt.element_img}
              />
            </div>
          );
        })}
    </>
  );
}
