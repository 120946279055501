import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { APIURL } from "../../config";
import { ProductMargin } from "./ProductMargin";
import axios from "axios";

export function Templates(editor) {
  const {
    Canvaseditor,
    setshowUploadToolbar,
    setelementStrokColor,
    setTextToolbar,
    setShowToolbar,
    setLoader,
    loader,
    closeTab,
  } = editor;
  const [categories, setCategories] = useState([]);
  const [templates, settemplates] = useState([]);
  const [Productmargin, setProductmargin] = useState(false);
  const { createID, updateID, loadCategories } = useParams();
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    // Set Loader.
    setLoader(true);

    // Set category dropdown.
    let get_categories = "";
    if (loadCategories) {
      get_categories = `${APIURL}/api/v1/categories/${loadCategories}`;
    } else {
      get_categories = `${APIURL}/api/v1/categories`;
    }
    axios
      .get(get_categories)
      .then(function (response) {
        if (response.data.data.length > 0) {
          // Set canvas width & height during create.

          if (createID || updateID) {
            const get_categoryinfo = `${APIURL}/api/v1/categoryinfo`;
            axios
              .post(get_categoryinfo, {
                template_id: createID || updateID,
              })
              .then(function (catres) {
                if (catres.data.data.length > 0) {
                  let canvas_width_px = catres.data.data[0].canvas_width_px;
                  let canvas_height_px = catres.data.data[0].canvas_height_px;
                  let ratio = Math.min(
                    600 / canvas_width_px,
                    600 / canvas_height_px
                  );

                  if (Canvaseditor) {
                    // Set category dropdown.
                    setCategories(catres.data.data);

                    // Set dimensions.
                    Canvaseditor.setDimensions({
                      width: canvas_width_px * ratio,
                      height: canvas_height_px * ratio,
                    });

                    // Set product margin.
                    setProductmargin(true);

                    if (updateID) {
                      const get_template = `${APIURL}/api/v1/template`;
                      axios
                        .get(get_template, {
                          params: {
                            template_id: updateID,
                          },
                        })
                        .then(function (template) {
                          if (template.data.data.jasondata) {
                            const json_data = unescape(
                              template.data.data.jasondata
                            );
                            Canvaseditor.loadFromJSON(
                              json_data,
                              Canvaseditor.renderAll.bind(Canvaseditor),
                              function () {
                                Canvaseditor.renderAll(Canvaseditor);
                              }
                            );

                            // Mouse down event.
                            Canvaseditor.on("mouse:down", function () {
                              if (Canvaseditor.getActiveObject()) {
                                const type =
                                  Canvaseditor.getActiveObject().get("type");
                                setShowToolbar(true);
                                if (type === "textbox") {
                                  setTextToolbar(true);
                                  setelementStrokColor(false);
                                  setshowUploadToolbar(false);
                                } else if (type === "image") {
                                  setTextToolbar(false);
                                  setelementStrokColor(false);
                                  setshowUploadToolbar(true);
                                } else {
                                  setTextToolbar(false);
                                  setelementStrokColor(true);
                                  setshowUploadToolbar(false);
                                }
                              }
                            });
                          }
                        });
                    }

                    document.getElementById("cover-spin").style.display =
                      "none";
                      
                    // Hide Loader.
                    setLoader(false);
                  }
                }
              });
          } else {
            // Set category dropdown.
            setCategories(response.data.data);

            // Set first category in dropdown.
            setSelectedOption(response.data.data[0].cat_slug);

            // Load template of first category.
            const get_category = `${APIURL}/api/v1/category`;

            axios
              .post(get_category, {
                cat_slug: response.data.data[0].cat_slug,
              })
              .then(function (response) {
                const result = response.data.data.filter(function (el) {
                  return el.jasondata !== null;
                });

                // Set templates.
                settemplates(result);

                // Load template in canvas.
                loadTemplate(
                  result[0].jasondata,
                  result[0].canvas_width_px,
                  result[0].canvas_height_px,
                  result[0].canvas_width_in,
                  result[0].canvas_height_in
                );

                // Hide Loader.
                setLoader(false);
              })
              .catch(function (error) {
                // Hide Loader.
                setLoader(false);
                console.log(error);
              });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // Update canvas size on mount and whenever the window is resized.
    updateCanvasSize();
    window.addEventListener("resize", updateCanvasSize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", updateCanvasSize);
    };

  }, [
    Canvaseditor,
    createID,
    updateID,
    setShowToolbar,
    setTextToolbar,
    setelementStrokColor,
    setshowUploadToolbar,
  ]);

  // Category change event.
  const handleChange = (event) => {
    // Set default option.
    setSelectedOption(event.target.value);

    const get_category = `${APIURL}/api/v1/category`;
    // Set Loader.
    setLoader(true);

    axios
      .post(get_category, {
        cat_slug: event.target.value,
      })
      .then(function (response) {
        const result = response.data.data.filter(function (el) {
          return el.jasondata !== null;
        });
        settemplates(result);
        // Hide Loader.
        setLoader(false);
      })
      .catch(function (error) {
        // Hide Loader.
        setLoader(false);
        console.log(error);
      });
  };

  // Handle click event.
  const loadTemplate = (jsonData, width, height, width_in, height_in) => {
    const canvas_width_px = width;
    const canvas_height_px = height;
    var ratio = Math.min(600 / canvas_width_px, 600 / canvas_height_px);
    const json_data = unescape(jsonData);
    // Set dimensions of canvas.
    Canvaseditor.setDimensions({
      width: canvas_width_px * ratio,
      height: canvas_height_px * ratio,
    });

    // Set product safe margin.
    // setProductmargin(true);

    // Load canvas details.
    Canvaseditor.loadFromJSON(
      json_data,
      Canvaseditor.renderAll.bind(Canvaseditor),
      function () {
        Canvaseditor.renderAll(Canvaseditor);
      }
    );

    // Mouse down event.
    Canvaseditor.on("mouse:down", function () {
      if (Canvaseditor.getActiveObject()) {
        const type = Canvaseditor.getActiveObject().get("type");
        setShowToolbar(true);

        if (type === "textbox") {
          setTextToolbar(true);
          setelementStrokColor(false);
          setshowUploadToolbar(false);
        } else if (type === "image") {
          setTextToolbar(false);
          setelementStrokColor(false);
          setshowUploadToolbar(true);
        } else {
          setTextToolbar(false);
          setelementStrokColor(true);
          setshowUploadToolbar(false);
        }
      }
    });

    // Unselect cut & safe margin.
    var activeObject = Canvaseditor.getActiveObject();
    if (activeObject && activeObject.type === "group") {
      activeObject.selectable = false;
      activeObject.evented = false;
      activeObject.hoverCursor = "default";
      Canvaseditor.discardActiveObject().renderAll();
    }

    // Set width & height in inch for svg to pdf in WordPress.
    localStorage.removeItem("canvas_size");
    localStorage.setItem("canvas_size", width_in + "-" + height_in);

    // Update canvas size on mount and whenever the window is less than 770.
    const resizeWidth = window.innerWidth;
    if (resizeWidth <= 770) {
      updateCanvasSize();
      closeTab();
    }
  };

  // Function to update the canvas size based on the container size (Responsive).
  const updateCanvasSize = () => {
    if (Canvaseditor) {
      const newWidth = window.innerWidth <= 1000 ? window.innerWidth : 1000;
      if (Canvaseditor.width != newWidth) {
        const scaleX = newWidth / Canvaseditor.width;
        var objects = Canvaseditor.getObjects();
        for (var i in objects) {
          objects[i].scaleX = objects[i].scaleX * scaleX;
          objects[i].scaleY = objects[i].scaleY * scaleX;
          objects[i].left = objects[i].left * scaleX;
          objects[i].top = objects[i].top * scaleX;
          objects[i].setCoords();
        }
        var obj = Canvaseditor.backgroundImage;
        if (obj) {
          obj.scaleX = obj.scaleX * scaleX;
          obj.scaleY = obj.scaleY * scaleX;
        }

        Canvaseditor.discardActiveObject();
        Canvaseditor.setWidth(Canvaseditor.getWidth() * scaleX);
        Canvaseditor.setHeight(Canvaseditor.getHeight() * scaleX);
        Canvaseditor.renderAll();
        Canvaseditor.calcOffset();
      }
    }
  };

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <ProductMargin
        Canvaseditor={Canvaseditor}
        Productmargin={Productmargin}
      />
      {(createID || updateID || loader) && <div id="cover-spin"></div>}
      <select
        id="templates"
        className="form-select"
        onChange={handleChange}
        disabled={createID || updateID}
        value={selectedOption}
      >
        <option value="">Please select one</option>
        {categories.length > 0 &&
          categories.map(function (item, i) {
            return (
              <option key={i} value={item.cat_slug}>
                {item.cat_name}
              </option>
            );
          })}
      </select>
      <div className="template-row">
        {templates.length > 0 &&
          templates.map(function (templateItem, index) {
            return (
              <div className="template-column" key={index}>
                <img
                  src={templateItem.images}
                  alt="templates"
                  onClick={() =>
                    loadTemplate(
                      templateItem.jasondata,
                      templateItem.canvas_width_px,
                      templateItem.canvas_height_px,
                      templateItem.canvas_width_in,
                      templateItem.canvas_height_in
                    )
                  }
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}
