import React, { useState, useEffect } from 'react';
import { Icon, Button } from '@blueprintjs/core';

export function LockElement(editor) {
  const [LockObj, setLockObj] = useState('unlock');
  const { Canvaseditor, setdisabledObj } = editor;
  useEffect(() => {
    // On object select.
    Canvaseditor.on('selection:created', function () {
      setLockObj('unlock');
    });
  });

  // Set Lock Object.
  const handleLockObj = () => {
    if (LockObj === 'unlock') {
      setLockObj('lock');
      var activeObject = Canvaseditor.getActiveObject();
      if (activeObject) {
        activeObject.selectable = false;
        activeObject.evented = false;
        activeObject.hoverCursor = 'default';
        Canvaseditor.discardActiveObject().renderAll();
      }
    } else {
      setdisabledObj(false);
      setLockObj('unlock');
      var items = Canvaseditor.getObjects();

      if (!items) {
        return;
      }

      items.forEach(function (item) {
        if (item.selectable === false) {
          item.selectable = true;
          item.hoverCursor = 'move';
          item.evented = true;
        }
      });
      Canvaseditor.discardActiveObject().renderAll();
    }
  };
  return (
    <div className="lockObj">
      <Button onClick={handleLockObj} icon={<Icon icon={LockObj} />} />
    </div>
  );
}
