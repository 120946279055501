import React from 'react';
import { fabric } from 'fabric';
import { Icon } from '@blueprintjs/core';

export function UploadComponet(editor) {
  const {
    Canvaseditor,
    setShowToolbar,
    setelementStrokColor,
    setTextToolbar,
    setshowUploadToolbar,
    closeTab,
  } = editor;

  const uploadImage = (e) => {
    var x = e.target;
    setShowToolbar(true);
    setshowUploadToolbar(true);
    for (var i = 0; i < x.files.length; i++) {
      var file = x.files[i];
      var fsize = Math.round(file.size / 1024);
      if (fsize > 2048) {
        alert("File too big, please select a file less than 2mb");
        setShowToolbar(false);
        setshowUploadToolbar(false);
        return;
      }
      var reader = new FileReader();
      reader.onload = function (f) {
        const data = f.target.result;
        fabric.Image.fromURL(data, function (img) {
          img.scaleToWidth(200);
          img.scaleToHeight(200);
          img.on("selected", function () {
            setTextToolbar(false);
            setelementStrokColor(false);
            setshowUploadToolbar(true);
          });
          Canvaseditor.add(img).setActiveObject(img);
          Canvaseditor.centerObject(img);
          Canvaseditor.renderAll();
        });
      };
      reader.readAsDataURL(file);
    }
    // Close tab event.
    const resizeWidth = window.innerWidth;
    if (resizeWidth <= 770) {
      closeTab();
    }
  };

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ height: '45px', paddingTop: '5px' }}>
        Do you want to upload your own images?
      </div>
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="input-file" className="bp4-button" style={{ width: '100%' }}>
          <Icon size="18" icon="upload" />
          <span className="bp4-button-text">Upload Image</span>
        </label>
        <input onChange={uploadImage} type="file" id="input-file" style={{ display: 'none' }} />
      </div>
    </div>
  );
}
