import React, { useEffect, useState } from "react";
import { APIURL } from "../../config";
import axios from "axios";

export function ElementsCategories(editor) {
  const { setElements } = editor;
  const [Categories, setCategories] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    // Set elements categories.
    const elements_categories = `${APIURL}/api/v1/elements-categories`;
    axios
      .get(elements_categories)
      .then(function (response) {
        if (response.data.data.length > 0) {
          setCategories(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // onChange event for elements.
  const onChange = (event) => {
    if (event.target.value) {
      const get_elements = `${APIURL}/api/v1/elements`;
      // Set Loader.
      setLoader(true);

      axios
        .post(get_elements, {
          cat_slug: event.target.value,
        })
        .then(function (response) {
          const result = response.data.data.filter(function (el) {
            return el.jasondata !== null;
          });

          setElements(result);
          // Hide Loader.
          setLoader(false);
        })
        .catch(function (error) {
          // Hide Loader.
          setLoader(false);
          console.log(error);
        });
    }
  };
  return (
    <>
      {loader && <div id="cover-spin"></div>}
      {Categories.length > 0 && (
        <select onChange={onChange} className="form-select element_cat">
          <option value="">Please select one</option>
          {Categories.map((ElementCat) => (
            <option key={ElementCat.cat_slug} value={ElementCat.cat_slug}>
              {ElementCat.cat_name}
            </option>
          ))}
        </select>
      )}
    </>
  );
}
