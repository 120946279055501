import React, { useState } from 'react';
import { Classes, Popover2 } from '@blueprintjs/popover2';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import { Icon, Button, Menu, MenuItem } from '@blueprintjs/core';

export function FlipPosition(editor) {
  const { Canvaseditor } = editor;
  const [eltFlipX, seteltFlipX] = useState(true);
  const [eltFlipY, seteltFlipY] = useState(true);

  // Handle flip image.
  const flipImage = (position) => {
    if (position === 'flipX') {
      if (eltFlipX === true) {
        seteltFlipX(false);
        Canvaseditor.getActiveObject().set('flipX', true);
      } else {
        seteltFlipX(true);
        Canvaseditor.getActiveObject().set('flipX', false);
      }
    } else {
      if (eltFlipY === true) {
        seteltFlipY(false);
        Canvaseditor.getActiveObject().set('flipY', true);
      } else {
        seteltFlipY(true);
        Canvaseditor.getActiveObject().set('flipY', false);
      }
    }
    Canvaseditor.renderAll();
  };

  return (
    <div className="flip" style={{ marginRight: '5px' }}>
      <Popover2
        interactionKind="click"
        popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
        placement="bottom"
        content={
          <Menu>
            <MenuItem
              onClick={() => flipImage('flipX')}
              icon="arrows-horizontal"
              text="Flip horizontally"
            />
            <MenuItem
              onClick={() => flipImage('flipY')}
              icon="arrows-vertical"
              text="Flip vertically"
            />
          </Menu>
        }
        renderTarget={({ ref, ...targetProps }) => (
          <Button {...targetProps} elementRef={ref} icon={<Icon icon="layers" />} text="Flip" />
        )}
      />
    </div>
  );
}
