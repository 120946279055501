import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

export function ElementStrokColor(editor) {
  const { Canvaseditor } = editor;

  const [elementColorPicker, setElementColorPicker] = useState({
    displayColorPicker: false,
    color: {
      r: '0',
      g: '161',
      b: '255',
      a: '100'
    }
  });

  const [elementStrokeColorPicker, setelementStrokeColorPicker] = useState({
    displayColorPicker: false,
    color: {
      r: '98',
      g: '197',
      b: '255',
      a: '100'
    }
  });
  // Set css for elements color picker.
  const elementStyles = reactCSS({
    default: {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `rgba(${elementColorPicker.color.r}, ${elementColorPicker.color.g}, ${elementColorPicker.color.b}, ${elementColorPicker.color.a})`
      },
      swatch: {
        display: 'inline-block',
        background: `rgb(${elementColorPicker.color.r}, ${elementColorPicker.color.g}, ${elementColorPicker.color.b})`,
        width: '36px',
        height: '33px',
        borderRadius: '2px',
        marginRight: '5px',
        boxShadow: '0 0 2px rgb(16 22 26 / 30%)',
        cursor: 'pointer'
      },
      popover: {
        position: 'fixed',
        zIndex: '2'
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
      }
    }
  });

  const elementStrokeStyles = reactCSS({
    default: {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `rgba(${elementStrokeColorPicker.color.r}, ${elementStrokeColorPicker.color.g}, ${elementStrokeColorPicker.color.b}, ${elementStrokeColorPicker.color.a})`
      },
      swatch: {
        display: 'inline-block',
        background: `rgb(${elementStrokeColorPicker.color.r}, ${elementStrokeColorPicker.color.g}, ${elementStrokeColorPicker.color.b})`,
        width: '36px',
        height: '33px',
        borderRadius: '2px',
        boxShadow: '0 0 2px rgb(16 22 26 / 30%)',
        cursor: 'pointer'
      },
      popover: {
        position: 'fixed',
        zIndex: '2'
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
      }
    }
  });

  // Element color picker.
  const handleElementColorPickerClick = () => {
    setElementColorPicker({
      displayColorPicker: !elementColorPicker.displayColorPicker,
      color: {
        r: '0',
        g: '0',
        b: '0',
        a: '1'
      }
    });
  };

  const handleElementColorPickerClose = () => {
    setElementColorPicker({
      displayColorPicker: false,
      color: elementColorPicker.color
    });
  };

  const handleElementColorPickerChange = (color) => {
    if (Canvaseditor.getActiveObject()) {
      setElementColorPicker({
        displayColorPicker: true,
        color: color.rgb
      });

      Canvaseditor.getActiveObject().set('fill', color.hex);
      Canvaseditor.renderAll();
    }
  };

  // Stroke color picker for element.
  const handleElementStrokeColorPickerClick = () => {
    setelementStrokeColorPicker({
      displayColorPicker: !elementStrokeColorPicker.displayColorPicker,
      color: {
        r: '0',
        g: '0',
        b: '0',
        a: '1'
      }
    });
  };

  const handleElementStrokeColorPickerClose = () => {
    setelementStrokeColorPicker({
      displayColorPicker: false,
      color: elementStrokeColorPicker.color
    });
  };

  const handleElementStrokeColorPickerChange = (color) => {
    if (Canvaseditor.getActiveObject()) {
      setelementStrokeColorPicker({
        displayColorPicker: true,
        color: color.rgb
      });
      Canvaseditor.getActiveObject().set('stroke', color.hex);
      if( Canvaseditor.getActiveObject().fromSVG !== undefined ){
        Canvaseditor.getActiveObject().set('strokeWidth', 5);
      }
      Canvaseditor.renderAll();
    }
  };

  return (
    <div className="font-color-container">
      <div style={{ float: 'left' }}>
        <div style={elementStyles.swatch} onClick={handleElementColorPickerClick}>
          <div style={elementStyles.color} />
        </div>
        {elementColorPicker.displayColorPicker ? (
          <div style={elementStyles.popover}>
            <div style={elementStyles.cover} onClick={handleElementColorPickerClose} />
            <SketchPicker
              color={elementColorPicker.color}
              onChange={handleElementColorPickerChange}
            />
          </div>
        ) : null}
      </div>
      <div style={{ display: 'inline-block' }}>
        <div style={elementStrokeStyles.swatch} onClick={handleElementStrokeColorPickerClick}>
          <div style={elementStrokeStyles.color} />
        </div>
        {elementStrokeColorPicker.displayColorPicker ? (
          <div style={elementStrokeStyles.popover}>
            <div style={elementStrokeStyles.cover} onClick={handleElementStrokeColorPickerClose} />
            <SketchPicker
              color={elementStrokeColorPicker.color}
              onChange={handleElementStrokeColorPickerChange}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
