import React, { useState } from 'react';

export function FontStyle(editor) {
  const { Canvaseditor } = editor;
  const [textBoldStyle, setTextBoldStyle] = useState(true);
  const [textItalicStyle, setItalicStyle] = useState(true);
  const [textUnderLineStyle, setUnderLineStyle] = useState(true);
  // Set bold font style.
  const handleSetBoldText = (e) => {
    if (Canvaseditor.getActiveObject()) {
      if (textBoldStyle === false) {
        e.target.style.opacity = '0.5';
        Canvaseditor.getActiveObject().set('fontWeight', '');
        Canvaseditor.renderAll();
        setTextBoldStyle(true);
      } else {
        e.target.style.opacity = '1';
        Canvaseditor.getActiveObject().set('fontWeight', 'bold');
        Canvaseditor.renderAll();
        setTextBoldStyle(false);
      }
    }
  };
  // Set Italic font style.
  const handleSetItalicText = (e) => {
    if (Canvaseditor.getActiveObject()) {
      if (textItalicStyle === false) {
        e.target.style.opacity = '0.5';
        Canvaseditor.getActiveObject().set('fontStyle', '');
        Canvaseditor.renderAll();
        setItalicStyle(true);
      } else {
        e.target.style.opacity = '1';
        Canvaseditor.getActiveObject().set('fontStyle', 'italic');
        Canvaseditor.renderAll();
        setItalicStyle(false);
      }
    }
  };
  // Set underLine font style.
  const handleSetUnderLineText = (e) => {
    if (Canvaseditor.getActiveObject()) {
      if (textUnderLineStyle === false) {
        e.target.style.opacity = '0.5';
        Canvaseditor.getActiveObject().set('underline', false);
        Canvaseditor.renderAll();
        setUnderLineStyle(true);
      } else {
        e.target.style.opacity = '1';
        Canvaseditor.getActiveObject().set('underline', true);
        Canvaseditor.renderAll();
        setUnderLineStyle(false);
      }
    }
  };
  // Set left align for text.
  const handleSetLeftAlign = (e) => {
    if (Canvaseditor.getActiveObject()) {
      e.target.style.opacity = '1';
      document.getElementById('txtright').style.opacity = '0.5';
      document.getElementById('txtcenter').style.opacity = '0.5';
      Canvaseditor.getActiveObject().set('textAlign', 'left');
      Canvaseditor.renderAll();
    }
  };
  // Set center align for text.
  const handleSetCenterAlign = (e) => {
    if (Canvaseditor.getActiveObject()) {
      e.target.style.opacity = '1';
      document.getElementById('txtleft').style.opacity = '0.5';
      document.getElementById('txtright').style.opacity = '0.5';
      Canvaseditor.getActiveObject().set('textAlign', 'center');
      Canvaseditor.renderAll();
    }
  };

  // Set right align for text.
  const handleSetRightAlign = (e) => {
    if (Canvaseditor.getActiveObject()) {
      e.target.style.opacity = '1';
      document.getElementById('txtleft').style.opacity = '0.5';
      document.getElementById('txtcenter').style.opacity = '0.5';
      Canvaseditor.getActiveObject().set('textAlign', 'right');
      Canvaseditor.renderAll();
    }
  };

  return (
    <>
      <div className="txtbold">
        <img alt="text-bold" onClick={handleSetBoldText} src={process.env.PUBLIC_URL + "/img/bold-icon.png"} />
      </div>
      <div className="txtitalic">
        <img alt="text-italic" onClick={handleSetItalicText} src={process.env.PUBLIC_URL + "/img/Italic-icon.png"} />
      </div>
      <div className="txtunderline">
        <img alt="text-underline" onClick={handleSetUnderLineText} src={process.env.PUBLIC_URL + "/img/underline-icon.png"} />
      </div>
      <div className="txtleft">
        <img
          id="txtleft"
          alt="text-left"
          onClick={handleSetLeftAlign}
          src={process.env.PUBLIC_URL + "/img/leftalign-icon.png"}
        />
      </div>
      <div className="txtcenter">
        <img
          id="txtcenter"
          onClick={handleSetCenterAlign}
          alt="text-center"
          src={process.env.PUBLIC_URL + "/img/center-icon.png"}
        />
      </div>
      <div className="txtright">
        <img
          id="txtright"
          onClick={handleSetRightAlign}
          alt="text-right"
          src={process.env.PUBLIC_URL + "/img/right-icon.png"}
        />
      </div>
    </>
  );
}
