import React, { useState } from 'react';
import { Classes, Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Icon, Button, mergeRefs, Slider } from '@blueprintjs/core';

export function Transparency(editor) {
  const { Canvaseditor, disabledObj } = editor;
  const [Transparency, setTransparency] = useState(1);
  // Set Transparency.
  const handleTransparency = (e) => {
    if (Canvaseditor.getActiveObject()) {
      let transparencyNum = '';
      if (isNaN(e)) {
        setTransparency(100);
        transparencyNum = 100;
      } else if (e > 100) {
        setTransparency(100);
        transparencyNum = 100;
      } else {
        setTransparency(e);
        transparencyNum = e;
      }

      Canvaseditor.getActiveObject().set('opacity', transparencyNum);
      Canvaseditor.renderAll();
    }
  };

  return (
    <div className="transparency">
      <Popover2
        interactionKind="click"
        popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
        placement="bottom"
        content={
          <div style={{ padding: '10px 20px' }}>
            <div style={{ textAlign: 'center' }}>Transparency</div>
            <div style={{ display: 'flex' }}>
              <div style={{ paddingTop: '8px', paddingRight: '20px' }}>
                <Slider
                  min={0}
                  max={1}
                  stepSize={0.1}
                  labelStepSize={1}
                  onChange={handleTransparency}
                  value={Transparency}
                  vertical={false}
                />
              </div>
            </div>
          </div>
        }
        renderTarget={({ isPopoverOpen, ref: ref1, ...popoverProps }) => (
          <Tooltip2
            content="Transparency"
            disabled={isPopoverOpen}
            openOnTargetFocus={false}
            placement="bottom"
            renderTarget={({ ref: ref2, ...tooltipProps }) => (
              <Button
                disabled={disabledObj}
                {...popoverProps}
                {...tooltipProps}
                active={isPopoverOpen}
                elementRef={mergeRefs(ref1, ref2)}
                icon={<Icon icon="tint" />}
              />
            )}
          />
        )}
      />
    </div>
  );
}
